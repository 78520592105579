



































































































































































































































































































































































































































































/deep/ .framePage .framePage-body {
  background: none;
  padding: 0;

  .framePage-scroll {
    padding: 0;

    .ovy-a {
      padding: 0;
    }
  }
}

.title_1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.t_1 {
  height: 302px;

  >.el-col {
    height: 100%;
    padding: 0 10px;

    .shell {
      height: 100%;

      .sxn {
        background: #fff;
        border-radius: 20px;
        padding: 20px 20px 10px 20px;
      }
    }
  }

  .t_1_3 {
    .shell {
      display: flex;
      flex-direction: column;
    }

    .t_1_3_1 {
      margin-bottom: 3%;
      margin-right: 3%;

      >div {
        background: #D9F4FF;
      }
    }

    .t_1_3_2 {
      margin-bottom: 3%;

      >div {
        background: #E7EDFF;
      }
    }

    .t_1_3_3 {
      margin-right: 3%;

      >div {
        background: #DCFAF8;
      }
    }

    .t_1_3_4 {
      >div {
        background: #FFF5D9;
      }
    }

    .sxn {
      .el-col {
        height: 45%;
        width: 48.5%;
        position: relative;

        .dw_f {
          height: 100%;
          border-radius: 16px;
          cursor: pointer;

          .tt_1 {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-left: 20px;
          }

          .tt_2 {
            .tt_2_1 {
              color: #666666;
              margin-bottom: 10px;
            }

            .tt_2_2 {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }

        .dw_c {
          display: none;
          position: absolute;
          z-index: 2;
          right: 8px;
          top: 80px;
          padding: 10px;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);

          >div {
            margin-bottom: 6px;

            .ts_1 {
              background: #D9F4FF;
            }

            .ts_2 {
              background: #E7EDFF;
            }

            .ts_3 {
              background: #DCFAF8;
            }

            .ts_4 {
              background: #FFF5D9;
            }

            .dw_c_1 {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin-right: 6px;
            }

            .dw_c_2 {
              color: #666666;
            }

            .dw_c_3 {
              margin-left: 20px;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        &:hover {
          .dw_c {
            display: block;
          }
        }
      }
    }
  }
}

.t_2 {
  .t_2_1 {
    .t_2_1_1 {
      margin-right: 10px;
    }
  }

  .t_2_2 {
    >div {
      width: 20%;
      margin-right: 20px;
      background: #fff;
      border-radius: 20px;
      padding: 20px 10px;

      &:last-child {
        margin-right: 0;
      }

      .tt_1 {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        margin-left: 20px;
      }

      .tt_2 {
        .tt_2_1 {
          color: #666666;
          margin-bottom: 10px;
        }

        .tt_2_2 {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}

.t_3 {
  .t_3_2 {
    background: #fff;
    height: calc(100% - 80px);
    padding-top: 20px;
  }
}
