 /deep/ .framePage .framePage-body {
  background: none;
  padding: 0;
}
 /deep/ .framePage .framePage-body .framePage-scroll {
  padding: 0;
}
 /deep/ .framePage .framePage-body .framePage-scroll .ovy-a {
  padding: 0;
}
.title_1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.t_1 {
  height: 302px;
}
.t_1 > .el-col {
  height: 100%;
  padding: 0 10px;
}
.t_1 > .el-col .shell {
  height: 100%;
}
.t_1 > .el-col .shell .sxn {
  background: #fff;
  border-radius: 20px;
  padding: 20px 20px 10px 20px;
}
.t_1 .t_1_3 .shell {
  display: flex;
  flex-direction: column;
}
.t_1 .t_1_3 .t_1_3_1 {
  margin-bottom: 3%;
  margin-right: 3%;
}
.t_1 .t_1_3 .t_1_3_1 > div {
  background: #D9F4FF;
}
.t_1 .t_1_3 .t_1_3_2 {
  margin-bottom: 3%;
}
.t_1 .t_1_3 .t_1_3_2 > div {
  background: #E7EDFF;
}
.t_1 .t_1_3 .t_1_3_3 {
  margin-right: 3%;
}
.t_1 .t_1_3 .t_1_3_3 > div {
  background: #DCFAF8;
}
.t_1 .t_1_3 .t_1_3_4 > div {
  background: #FFF5D9;
}
.t_1 .t_1_3 .sxn .el-col {
  height: 45%;
  width: 48.5%;
  position: relative;
}
.t_1 .t_1_3 .sxn .el-col .dw_f {
  height: 100%;
  border-radius: 16px;
  cursor: pointer;
}
.t_1 .t_1_3 .sxn .el-col .dw_f .tt_1 {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-left: 20px;
}
.t_1 .t_1_3 .sxn .el-col .dw_f .tt_2 .tt_2_1 {
  color: #666666;
  margin-bottom: 10px;
}
.t_1 .t_1_3 .sxn .el-col .dw_f .tt_2 .tt_2_2 {
  font-size: 20px;
  font-weight: 600;
}
.t_1 .t_1_3 .sxn .el-col .dw_c {
  display: none;
  position: absolute;
  z-index: 2;
  right: 8px;
  top: 80px;
  padding: 10px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div {
  margin-bottom: 6px;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .ts_1 {
  background: #D9F4FF;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .ts_2 {
  background: #E7EDFF;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .ts_3 {
  background: #DCFAF8;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .ts_4 {
  background: #FFF5D9;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .dw_c_1 {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .dw_c_2 {
  color: #666666;
}
.t_1 .t_1_3 .sxn .el-col .dw_c > div .dw_c_3 {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 600;
}
.t_1 .t_1_3 .sxn .el-col:hover .dw_c {
  display: block;
}
.t_2 .t_2_1 .t_2_1_1 {
  margin-right: 10px;
}
.t_2 .t_2_2 > div {
  width: 20%;
  margin-right: 20px;
  background: #fff;
  border-radius: 20px;
  padding: 20px 10px;
}
.t_2 .t_2_2 > div:last-child {
  margin-right: 0;
}
.t_2 .t_2_2 > div .tt_1 {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-left: 20px;
}
.t_2 .t_2_2 > div .tt_2 .tt_2_1 {
  color: #666666;
  margin-bottom: 10px;
}
.t_2 .t_2_2 > div .tt_2 .tt_2_2 {
  font-size: 20px;
  font-weight: 600;
}
.t_3 .t_3_2 {
  background: #fff;
  height: calc(100% - 80px);
  padding-top: 20px;
}
